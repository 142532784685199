
.ui {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    font-size: 16px;
    color: white;
    display: flex;
    justify-content: center;
    margin: auto;
}

.ui-cta-primary {
    background: red;
    padding: 1vh 2vw;
    text-align: center;
    display: block;
}
.ui-branding {
    position: fixed;
    top: 0;
    right: 0;
    color: white;
    padding: 6px 10px;
    font-weight: bold;
    background: black;
    transform-origin: 100% 100%;
    transform: rotate(-90deg);
}

.ui-intro {
    position: absolute;
    top: 0;
    height: 100%;
    margin: auto;
    left: 0;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    right: 0;
    min-width: 400px;
    width: 50%;
}

.ui-intro-instructions {
    text-align: center;
    h2,
    p {
        margin: 0;
        padding: 0;
    }

    h2 {
        margin: 3vh 0 1vh;
    }
}

.ui-settings ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
}

.ui-settings-range {
    width: 100%;
    /*     display: flex; */
    justify-content: flex-start;
}

.ui-input-row {
    display: flex;
    justify-content: space-between;
    margin: 1vh 0;
}

.ui-input-text,
.ui-input-label {
    flex: 0 1 auto;
}

.ui-input-range {
    width: 100%;
    background: black;
    display: block;
}

.ui-intro-logo {
    padding: 2vh;
    text-align: center;
    font-size: 10vw;
    color: white;
}

.ui-players {
    flex: 1 0 auto;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 2vh 0;
}

.ui-player {
    display: block;
    flex: 1 0 auto;
    color: black;
    font-size: 0.8em;
    margin: 14px;
}

.ui-player-card {
    padding: 1vh;
    flex: 1 0 auto;
    text-align: left;
    color: #bababa;
    width: 18%;
    margin: 1%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
}

.ui-player-card textarea {
    font-family: inherit;
    background: transparent;
    border: none;
    color: inherit;
    min-height: 3vh;
    font-size: 1em;
    overflow: hidden;
    margin-bottom: 1em;
    width: 100%;
}

.ui-player-color {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 10px solid white;
    margin: 15px auto;
}

.ui-player-info {
    position: absolute;
    bottom: 100%;
    left: 0;
    margin: 10px;
    display: block;
}

.swatch {
    flex: 1 0 auto;
    display: block;
    height: 10px;
    margin: 0;
}

.ui-cta-start {
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 5px;
}

.ui-icon {
    display: inline-block;
    height: 1em;
    margin: 0 0 1px 0;
    vertical-align: text-bottom;
}

/* game over *****************/

.ui-game-over-stats {
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    color: #333333;
    width: 100%;
    margin-bottom: 1vh;
}

.ui-score-row {
    margin: 0 0.3em;
}

.ui-score-points {
    color: white;
}

.ui-heading,
.ui-subheading,
.ui-score-row,
.ui-restart {
    color: white;
    padding: 0 1em;
    margin: auto;
    padding: 0.5vh;
    flex: 1 auto;
}

.ui-heading {
    font-size: 2.5vh;
    font-weight: 700;
    color: white;
    text-align: center;
}

.ui-score-avatar {
    display: none;
}

.ui-score-row {
    margin: auto;
}
.ui-score-col {
    padding: 0 0.3em 0;
    margin: auto;
}

/* status *********************/
.ui-status {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0.5vh;
    padding: 0;
}

.ui-status-cell {
    padding: 10px;
    flex: 1 0 auto;
    text-align: right;
}

.ui-status-game-over,
.ui-status-current-player {
    display: flex;
    color: white;
}

.ui-current {
    flex: auto;
    text-align: left;
    display: flex;
    width: 70%;
}
.ui-status-icons {
    display: flex;
    flex: auto;
    width: 30%;
}

.ui-player-label {
    flex: auto;
    margin: auto 1vw;
}

.ui-current-avatar,
.ui-current-player {
    display: inline-block;
    vertical-align: middle;
}
.ui-current-avatar {
    width: 1em;
    height: 1em;
    border-radius: 3em;
    background: #cccccc;
    margin: auto 0.5em auto 0;
    border: 2px solid #cfcfcf;
}

.ui-terrain-count::after {
    content: '#';
}

.ui-terrain {
    display: flex;
}

.ui-player-current.ui-player {
    color: white;
}

.ui-player-icon {
    text-align: center;
    height: 1vh;
    position: relative;
}

.ui-container {
    width: 100%;
    height: 100%;
}

.ui-player-spiecies {
    font-size: 15px;
}

.ui-settings {
    width: 34%;
    display: block;
    margin: auto;
    min-width: 400px;
}
